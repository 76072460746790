<template>
  <div class="forth-screen">
    <h1 class="review-title">{{ $t(review_title) }}</h1>
    <p class="review-detail">{{ $t(review_detail) }}</p>
    <div class="review-box">
      <div
        v-for="(item, index) in review_list"
        :key="`review-${index}`"
        class="review-item">
        <img class="user-img" :src="$t(item.img)" alt="User image">
        <p class="user-job">{{ $t(item.job) }}</p>
        <div class="star-box">
          <img
            class="star-img"
            v-for="(img, index) in item.star_number"
            :key="`star${index}`"
            :src="$t(star_img)"
            alt="Star image">
          <img
            class="grey-star-img"
            v-for="(img, index) in 5 - item.star_number"
            :key="`grey-star${index}`"
            :src="$t(grey_star_img)"
            alt="Grey star image">
        </div>
        <div class="gap"></div>
        <p class="comment-detail">{{ $t(item.content) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForthScreen',
  data() {
    return {
      review_title: 'review_title',
      review_detail: 'review_detail',
      review_list: [
        {
          img: 'review_img_1',
          job: 'review_job_1',
          star_number: 5,
          content: 'review_content_1',
        },
        {
          img: 'review_img_2',
          job: 'review_job_2',
          star_number: 5,
          content: 'review_content_2',
        },
        {
          img: 'review_img_3',
          job: 'review_job_3',
          star_number: 4,
          content: 'review_content_3',
        },
      ],
      star_img: 'star_img',
      grey_star_img: 'grey_star_img',
    };
  },
};
</script>

<style scoped lang="less">
  .forth-screen {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 0.2rem;
    overflow: hidden;
    .review-title {
      margin: 1.2rem 0 0.16rem;
      text-align: center;
      font-size: 0.48rem;
      font-family: SFProText-Bold, SFProText;
      font-weight: bold;
      color: #262626;
      line-height: 0.58rem;
    }
    .review-detail {
      margin-bottom: 0.8rem;
      text-align: center;
      font-size: 0.16rem;
      font-family: SFProText-Regular, SFProText;
      font-weight: 400;
      color: #4D4D4D;
      line-height: 0.19rem;
    }
    .review-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.64rem;
      .review-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 3.48rem;
        height: 4.62rem;
        padding: 0.56rem;
        background: #FFFFFF;
        border-radius: 8px;
        .user-img {
          width: 0.8rem;
          height: 0.8rem;
          margin-bottom: 0.24rem;
          border-radius: 50%;
        }
        .user-job {
          margin-bottom: 0.16rem;
          font-size: 0.18rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #262626;
          line-height: 0.25rem;
        }
        .star-box {
          margin-bottom: 0.24rem;
          .star-img, .grey-star-img {
            height: 0.2rem;
          }
        }
        .gap {
          margin-bottom: 0.24rem;
          width: 2.46rem;
          height: 1px;
          border: 1px solid #F0F3FA;
        }
        .comment-detail {
          width: 2.36rem;
          font-size: 0.16rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1A1A1A;
          line-height: 0.24rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
        }
      }
    }
  }
</style>
