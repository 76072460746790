<template>
  <div class="third-screen">
    <h1 class="text">{{ $t(third_screen_text) }}</h1>
    <button
      class="left-button button"
      :class="{ 'en-button': language === 'en' }"
      @click="downloadDirectly">
      <img :src="$t(button_download)" alt="Download">
      <span class="button-text">{{ $t(download_text) }}</span>
    </button>
  </div>
</template>

<script>
import languageMixin from './mixin/languageMixin';
import jumpMixin from './mixin/jumpMixin';

export default {
  name: 'ThirdScreen',
  mixins: [languageMixin, jumpMixin],
  data() {
    return {
      button_download: 'button_download',
      download_text: 'download_text',
      third_screen_text: 'third_screen_text',
    };
  },
};
</script>

<style scoped lang="less">
  .third-screen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 0 0.2rem;
    .text {
      font-size: 0.36rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #262626;
      line-height: 0.5rem;
    }
    .button {
      width: 2.22rem;
      height: 0.64rem;
      border-radius: 6px;
      cursor: pointer;
      /*img, .button-text {*/
      /*  vertical-align: middle;*/
      /*}*/
      img {
        height: 60%;
        margin-right: 0.18rem;
      }
      .button-text {
        font-size: 0.24rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.33rem;
      }
    }
    .left-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(to right, #FE552F, #FF8B37);
      img {
        width: 0.38rem;
        height: 0.38rem;
      }
    }
  }
</style>
