<template>
  <div class="second-screen">
    <div class="select-box">
      <div class="line">
        <div class="function-list">
          <div
            v-for="(item, index) in function_list"
            :key="item.value"
            :ref="`function_item_${index}`"
            :class="{'selected-function-item': selected_index === index}"
            class="function-item"
            @mouseenter="mouseEnterHandler(index)"
            @mouseleave="mouseLeaveHandler(index)">
            <div class="circle"></div>
            <h2 class="function-name-text">{{ $t(item.label) }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="function-img-box">
      <img
        v-for="(img, index) in function_list.map((item) => item.img)"
        :key="img"
        v-show="index === selected_index"
        class="function-img" :src="$t(img)" alt="Function image">
    </div>
<!--    <transition-group name="function-text" tag="div">-->
      <p
        v-for="(text, index) in function_list.map((item) => item.text)"
        :key="text"
        v-show="index === selected_index"
        class="function-detail-text">{{ $t(text) }}</p>
<!--    </transition-group>-->
  </div>
</template>

<script>
export default {
  name: 'SecondScreen',
  data() {
    return {
      // Picture
      pic_safety: 'pic_safety',
      selected_index: 0,
      // Functions
      function_list: [
        {
          label: 'function_label_1',
          value: 'camouflage',
          img: 'function_img_1',
          text: 'function_text_1',
        },
        {
          label: 'function_label_2',
          value: 'safety',
          img: 'function_img_2',
          text: 'function_text_2',
        },
        {
          label: 'function_label_3',
          value: 'no_trace',
          img: 'function_img_3',
          text: 'function_text_3',
        },
        {
          label: 'function_label_4',
          value: 'hide',
          img: 'function_img_4',
          text: 'function_text_4',
        },
        {
          label: 'function_label_5',
          value: 'unlimited',
          img: 'function_img_5',
          text: 'function_text_5',
        },
      ],
      // Interval
      timer: null,
    };
  },
  methods: {
    mouseEnterHandler(index) {
      this.selected_index = index;
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    mouseLeaveHandler(index) {
      this.selected_index = index;
      this.timer = setInterval(() => {
        this.selected_index = (this.selected_index + 1) % 5;
      }, 3600);
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.selected_index = (this.selected_index + 1) % 5;
    }, 3600);
  },
};
</script>

<style scoped lang="less">
  .second-screen {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .select-box {
      margin: 2.37rem 0 0.78rem;
      .line {
        width: 8rem;
        height: 0.02rem;
        margin: 0 auto;
        background: rgba(255, 255, 255, 0.2);
        .function-list {
          position: relative;
          .function-item {
            position: absolute;
            width: 0.1rem;
            height: 0.1rem;
            &:nth-child(1) {
              left: 0;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            &:nth-child(2) {
              left: 25%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            &:nth-child(3) {
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            &:nth-child(4) {
              left: 75%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            &:nth-child(5) {
              left: 100%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            .circle {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 0.02rem;
              height: 0.02rem;
              background: #0B0C13;
              border: 0.02rem solid #FFFFFF;
              border-radius: 50%;
              transition: 0.2s ease-in-out;
            }
            .function-name-text {
              position: absolute;
              left: 50%;
              top: -0.43rem;
              transform: translate(-50%, -50%);
              width: auto;
              font-size: 0.24rem;
              font-family: SFProText-Bold, SFProText;
              font-weight: bold;
              color: rgba(255, 255, 255, 0.6);
              white-space: nowrap;
            }
          }
          .selected-function-item {
            .circle {
              width: 0.21rem;
              height: 0.21rem;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              border: 4px solid #FFFFFF;
              border-radius: 50%;
            }
            .function-name-text {
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .function-img-box {
      width: 10.54rem;
      height: 4.85rem;
      margin: 0 auto 0.62rem;
      .function-img {
        position: relative;
        top: 50%;
        left: 50%;
        height: 4.5rem;
        transform: translate(-50%, -50%);
        /*width: 100%*/
      }
    }
    .function-detail-text {
      font-size: 0.18rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.88);
      line-height: 0.21rem;
      text-align: center;
    }
  }
</style>
