import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/utils/flexlib';
// 使用@报错???
import zh from './lang/zh.json';
import en from './lang/en.json';
import getLanguage from './utils/getLanguage';

Vue.config.productionTip = false;
Vue.use(VueI18n);

const messages = {
  zh,
  en,
};
const i18n = new VueI18n({
  locale: getLanguage(),
  silentFallbackWarn: true,
  messages,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
