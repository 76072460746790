<template>
  <div class="footer">
<!--    <img class="footer-logo" :src="$t(logo_footer)" alt="Footer logo">-->
    <div class="left-box">
      <span class="developer-text">{{ $t(developer_text) }}</span>
      <span class="version-text">{{$t(version_text)}}{{ version_details.version }}</span>
    </div>
    <div class="right-box">
      <span class="copyright-text">{{ copyright_text }}</span>
      <router-link to="/privacy_policy">{{ $t(privacy_agreement) }}</router-link>
      <router-link to="/privacy_policy">{{ $t(user_rights) }}</router-link>
      <select v-if="true" class="language-select" v-model="language">
        <option value="en" style="color: #000000">English</option>
        <option value="zh" style="color: #000000">简体中文</option>
      </select>
    </div>
  </div>
</template>

<script>
import VersionDetails from '../../public/json/VersionDetails.json';
import getLanguage from '../utils/getLanguage';

export default {
  name: 'Footer',
  data() {
    return {
      developer_text: 'developer_text',
      version_text: 'version_text',
      privacy_agreement: 'privacy_agreement',
      user_rights: 'user_rights',
      language: '',
      logo_footer: 'logo_footer',
      version_details: VersionDetails,
    };
  },
  computed: {
    copyright_text() {
      return this.language === 'zh'
        ? `版权所有 © 2015-${new Date().getFullYear()} 南京道成网络科技有限公司`
        : `Copyright ©2015-${new Date().getFullYear()} DaoCheng All Rights Reserved`;
    },
  },
  watch: {
    language(newVal) {
      let tempStr = '';
      switch (newVal) {
        case 'zh':
          tempStr = 'zh-CN';
          break;
        case 'en':
          tempStr = 'en';
          break;
        default:
      }
      this.$i18n.locale = tempStr;
      localStorage.setItem('lang', tempStr);
      this.$store.commit('setLanguage', {
        lang: tempStr,
      });
    },
  },
  mounted() {
    if (getLanguage().indexOf('zh') >= 0) {
      this.language = 'zh';
    } else {
      this.language = 'en';
    }
  },
};
</script>

<style scoped lang="less">
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    height: 0.52rem;
    margin: 0 auto;
    padding: 0 20px;
    .footer-logo {
      height: 0.25rem;
    }
    .developer-text, .version-text {
      margin-right: 0.12rem;
      font-size: 0.14rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 0.16rem;
    }
    .copyright-text {
      margin-right: 0.12rem;
      font-size: 0.14rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 0.16rem;
    }
    .language-select {
      color: #FFFFFF;
      background: transparent;
      cursor: pointer;
    }
    .right-box {
      a {
        margin-right: 0.06rem;
        font-size: 0.14rem;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
</style>
