import VersionDetails from '../../public/json/VersionDetails.json';

const channels = [
  'baidu21062801',
];
const version = 1;

export function getAppStoreUrl(ct) {
  if (channels.includes(ct)) {
    return `https://apps.apple.com/app/apple-store/id1561378187?pt=8024801&ct=${ct}&mt=8`;
  }
  return 'https://apps.apple.com/app/apple-store/id1561378187?pt=8024801&ct=baidu21062801&mt=8';
}

export function getDownloadUrl(channel, cid) {
  let dir = 'original';
  if (channels.includes(`${channel}${cid}`)) {
    dir = `ch${channel}_cid${cid}`;
  }
  return `https://filesafe-cdn.doviapps.com/download/${dir}/Filesafe_v${VersionDetails.package_version}.dmg?v=${version}`;
}
