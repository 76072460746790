<template>
  <div class="home">
    <div class="nav-wrap wrap" ref="nav_wrap">
      <Nav></Nav>
    </div>
    <div class="main-wrap wrap" :style="`background-image: url('${$t(bg_main)}')`">
      <div class="first-screen-wrap wrap">
        <first-screen></first-screen>
      </div>
      <div class="second-screen-wrap wrap">
        <second-screen></second-screen>
      </div>
    </div>
    <div class="third-screen-wrap wrap">
      <third-screen></third-screen>
    </div>
    <div class="forth-screen-wrap wrap">
      <forth-screen></forth-screen>
    </div>
    <div class="footer-wrap wrap">
      <six-screen></six-screen>
      <div class="gap"></div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Nav from './Nav.vue';
import FirstScreen from './FirstScreen.vue';
import SecondScreen from './SecondScreen.vue';
import ThirdScreen from './ThirdScreen.vue';
import ForthScreen from './ForthScreen.vue';
import SixScreen from './SixScreen.vue';
import Footer from './Footer.vue';

export default {
  name: 'Home',
  components: {
    Footer,
    SixScreen,
    ForthScreen,
    ThirdScreen,
    SecondScreen,
    FirstScreen,
    Nav,
  },
  data() {
    return {
      bg_main: 'bg_main',
      nav_scroll: null,
    };
  },
  mounted() {
    const navScrollHandler = () => {
      const scrollHeight = document.documentElement.scrollTop
        || document.body.scrollTop;
      const navWrapElement = this.$refs.nav_wrap;
      const baseHeight = 600;
      const transparent = scrollHeight >= baseHeight ? 1 : scrollHeight / baseHeight;
      if (navWrapElement) {
        navWrapElement.style.backgroundColor = `rgba(75, 76, 132, ${transparent})`;
      }
    };
    this.nav_scroll = navScrollHandler;
    document.addEventListener('scroll', this.nav_scroll);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.nav_scroll);
  },
};
</script>
<style lang="less">
  @min-width: 756px;
  @nav-height: 56px;
  .home {
    min-width: @min-width;
    overflow: hidden;
    .nav-wrap {
      position: fixed;
      width: 100%;
      z-index: 99;
      background-color: rgba(75, 76, 132, 0);
    }
    .main-wrap {
      height: 20.12rem;
      margin-top: calc(~'-@{nav-height}');
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      .first-screen-wrap {
        height: 10.06rem;
        /*min-height: 100vh;*/
        /*height: 9.66rem;*/
        /*margin-top: calc(~'-@{nav-height}');*/
        /*background-color: #F8F9FB;*/
        /*background-image: url("../../public/image/bg_banner@2x.png");*/
        /*background-repeat: no-repeat;*/
        /*background-position: top center;*/
        /*background-size: cover;*/
      }
      .second-screen-wrap {
        height: 10.06rem;
        /*background-color: #F8F9FB;*/
      }
    }
    .third-screen-wrap {
      height: 1.6rem;
      background-color: #FFD46E;
    }
    .forth-screen-wrap {
      background: #F6F9FE;
    }
    .footer-wrap {
      background: linear-gradient(360deg, #4B4C84 0%, #4C5E85 100%);
      .gap {
        border-top: 1.2px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
</style>
