export default function () {
  const str = localStorage.getItem('lang') || window.navigator.language;
  if (localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', str);
  }
  if (str.indexOf('zh') >= 0) {
    return 'zh';
  }
  return 'en';
}
