<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import getLanguage from './utils/getLanguage';

export default {
  created() {
    this.$store.commit('setLanguage', {
      lang: getLanguage(),
    });
  },
};
</script>

<style lang="less">
  @import url('./style/common.less');
</style>
