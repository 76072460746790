import { getDownloadUrl } from '../../utils/getAppStoreUrl';

export default {
  methods: {
    downloadDirectly() {
      const { query } = this.$route;
      window.open(getDownloadUrl(query.channel, query.cid));
    },
    jumpToAppStore() {
      const url = 'https://apps.apple.com/cn/app/filesafe/id1561378187?mt=12';
      window.open(url, '_blank');
    },
  },
};
