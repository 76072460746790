import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: '',
  },
  mutations: {
    setLanguage(state, payload) {
      state.language = payload.lang;
      document.title = state.language.indexOf('en') !== -1
        ? 'FileSafe'
        : '文件超安全';
    },
  },
  actions: {
  },
  modules: {
  },
});
